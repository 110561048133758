import { endOfDay } from "date-fns";
import { Form, Wizard } from "orion";
import { useCallback, useRef } from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import {
  dataSourceIdToDSName,
  genericErrorMessage,
  PRODUCT_ID,
  QUERIES,
} from "consts";
import useCreateMSP from "queryHooks/useCreateMSP/useCreateMSP";
import { showAlert } from "slices/alertsSlice";
import { asUTC } from "utils/timezone";
import styles from "./AddMSPWizard.module.scss";
import FormContainer from "./FormContainer";
import { initalValues, validationSchemaCreator } from "./helper";

const AddMSPWizard = ({ toggle }) => {
  const formRef = useRef();

  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const { mutate: createMSP } = useCreateMSP();

  const onSubmitHandler = useCallback(
    ({ edition, timezone, expiryDate, products, user, ...values }, formik) => {
      createMSP(
        {
          ...values,
          edition: edition.value,
          expiryDate: asUTC(endOfDay(expiryDate)).toISOString(),
          user: { ...user, role: 2, timezone: timezone.value },
          products: products
            .filter(({ enabled }) => enabled)
            .map(({ productID, skus, storageRegions, datasources }) => ({
              productID,
              skus: skus.map(({ value }) => value),
              storageRegions: storageRegions.map(({ value }) => value),
              datasources:
                productID === PRODUCT_ID.hybridWorkloads
                  ? [
                      {
                        name: "Hybrid Workloads",
                        isEnabled: true,
                      },
                    ]
                  : datasources.map((datasource) => ({
                      name: dataSourceIdToDSName[datasource.value],
                      isEnabled: true,
                    })),
            })),
        },
        {
          onError: (e) => {
            if (e.status === 411) {
              // TODO update after initialErrors props will be handled by Form
              formik.setErrors(e.data);
            } else {
              dispatch(
                showAlert({
                  message: e.data?.message || genericErrorMessage,
                  type: "danger",
                })
              );
            }
          },
          onSuccess: (data) => {
            if (data?.warning) {
              dispatch(
                showAlert({
                  message: data?.message,
                  type: "warning",
                })
              );
            } else {
              dispatch(
                showAlert({
                  message: "MSP account created successfully",
                  type: "success",
                })
              );
              queryClient.refetchQueries([QUERIES.getMspList]);
            }
            toggle();
          },
        }
      );
    },
    [createMSP, dispatch, queryClient, toggle]
  );

  return (
    <Form
      withValidation
      initialValues={initalValues}
      validationSchema={validationSchemaCreator}
      innerRef={formRef}
      onSubmit={onSubmitHandler}
      renderChildren={({ submitForm, ...formikProps }) => {
        return (
          <Wizard
            className={styles.wizard}
            isOpen={true}
            toggle={toggle}
            header="Add MSP"
            items={[{ component: () => <FormContainer {...formikProps} /> }]}
            onFinishClick={submitForm}
            disableFinish={!formikProps.isValid}
            finishLabel="Save"
            dataTestId="sportal-add-msp-container"
          />
        );
      }}
    />
  );
};

export default AddMSPWizard;
