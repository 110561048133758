import HelpGreyOutline from "@druva-apps/orion-icons/lib/HelpGreyOutline";
import {
  PRODUCT_ID,
  dataSourceTypes,
  dateFormatter,
  productIDToName,
} from "consts";
import { Button, Card, Col, LabelValue, Row, Spinner, Tooltip } from "orion";
import useMSP from "queryHooks/useMSP/useMSP";
import useUpdateMSPPermission from "queryHooks/useUpdateMSPPermission/useUpdateMSPPermission";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { closeModal, openModal } from "slices/modalsSlice";
import { getStatusString } from "utils/formatter";
import "./Summary.scss";
import {
  UPDATE_PERMISSIONS_FORM_KEY,
  Allowed_To_Delete,
  Allowed_To_Delete_Now,
  Allowed_To_Reset_Password,
  Allowed_To_Reset_Password_Admin_Email,
  Allowed_To_Reset_Password_Expiry_Date,
} from ".";
import { showAlert } from "slices/alertsSlice";
import { genericErrorMessage } from "consts";
import { formatInTimeZone } from "date-fns-tz";
import { useCallback } from "react";
import { asLocalTime } from "utils/timezone";

const Summary = () => {
  let { accountId } = useParams();
  const { data } = useMSP(accountId);
  const dispatch = useDispatch();

  const { isLoading, mutate: updatePermission } = useUpdateMSPPermission(
    accountId,
    {
      onSuccess: () => {
        dispatch(closeModal(UPDATE_PERMISSIONS_FORM_KEY));
      },
      onError: (e) => {
        dispatch(
          showAlert({
            message: e.data?.message || genericErrorMessage,
            type: "danger",
          })
        );
      },
    }
  );

  const handleEditClick = useCallback(() => {
    dispatch(
      openModal({
        key: UPDATE_PERMISSIONS_FORM_KEY,
        mspId: accountId,
        initialVaue: {
          [Allowed_To_Delete]: data?.permissions?.[Allowed_To_Delete],
          [Allowed_To_Delete_Now]: data?.permissions?.[Allowed_To_Delete_Now],
          [Allowed_To_Reset_Password]:
            data?.permissions?.[Allowed_To_Reset_Password],
          [Allowed_To_Reset_Password_Admin_Email]:
            data?.permissions?.[Allowed_To_Reset_Password_Admin_Email],
        },
        updatePermission: updatePermission,
      })
    );
  }, [updatePermission, dispatch, accountId, data?.permissions]);

  return (
    <>
      <Card
        fullHeight
        title="Summary"
        body={() => {
          return (
            <div className="oui-ml-1" data-testid="summary-card">
              <Row className="oui-mt-4">
                <Col sm="3">
                  <LabelValue
                    label="Customer Name"
                    value={data.organizationName}
                    truncateValue
                  />
                </Col>
                <Col sm="3">
                  <LabelValue
                    label="Administrator Email"
                    value={data.email}
                    truncateValue
                  />
                </Col>
                <Col sm="3">
                  <LabelValue
                    label="Expires On"
                    value={dateFormatter.format(asLocalTime(data.expiryDate))}
                  />
                </Col>
                <Col sm="3">
                  <LabelValue label="MSP ID" value={data.id} truncateValue />
                </Col>
              </Row>
              <Row className="oui-mt-4 oui-mb-4">
                <Col sm="3">
                  <LabelValue
                    label="SFDC ID"
                    value={data.sfdcId}
                    truncateValue
                  />
                </Col>
                <Col sm="3">
                  <LabelValue
                    label="License Type"
                    value={data.edition}
                    truncateValue
                    className="oui-text-capitalize"
                  />
                </Col>
              </Row>
            </div>
          );
        }}
      />
      <Row className="oui-mt-4 oui-mb-4">
        {data.products.map((details) => {
          const { productID, skus, storageRegions, datasources } = details;
          return (
            <Col sm="6" key={`license-card-${productID}`}>
              <Card
                title={productIDToName[productID]}
                fullHeight
                body={() => (
                  <>
                    <Row className="oui-mt-4 oui-mb-4">
                      <Col sm="6">
                        <LabelValue
                          label="Edition"
                          className="oui-text-capitalize"
                          value={skus.join(", ")}
                          truncateValue
                        />
                      </Col>
                      <Col sm="6">
                        <LabelValue
                          label="Storage Region"
                          className="oui-text-capitalize"
                          value={storageRegions.join(", ")}
                          truncateValue
                        />
                      </Col>
                    </Row>
                    {productID === PRODUCT_ID.saasAppsAndEndpoints ? (
                      <Row>
                        <Col sm="6">
                          <LabelValue
                            label="Product "
                            className="oui-text-capitalize"
                            value={datasources
                              ?.map(
                                (datasources) =>
                                  dataSourceTypes[datasources.name]
                              )
                              ?.join(", ")}
                            truncateValue
                          />
                        </Col>
                      </Row>
                    ) : null}
                  </>
                )}
              />
            </Col>
          );
        })}
      </Row>
      <Card
        fullHeight
        title={() => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Card.Title collapsible title="Advance Settings" />
            <Tooltip
              text="With this option enabled , you are letting MSP delete their customers"
              placement="right"
            >
              <HelpGreyOutline
                width={12}
                height={12}
                className="oui-ml-2"
                muted={false}
              />
            </Tooltip>
          </div>
        )}
        titleAction={() => (
          <Button
            onClick={handleEditClick}
            label="Edit"
            dataTestId="advance-setting-edit-button"
          />
        )}
        className="oui-mt-4 oui-mb-4"
        body={() => {
          if (isLoading) {
            return <Spinner />;
          }
          return (
            <div className="oui-ml-1">
              <Row className="oui-mt-4">
                <Col sm="3">
                  <LabelValue
                    label="Enable Delete Tenant"
                    value={getStatusString(
                      data?.permissions?.[Allowed_To_Delete]
                    )}
                  />
                </Col>
                <Col sm="3">
                  <LabelValue
                    label="Allow Delete Immediately"
                    value={getStatusString(
                      data?.permissions?.[Allowed_To_Delete_Now],
                      true
                    )}
                    truncateValue
                  />
                </Col>
              </Row>
              <Row className="oui-mt-4">
                <Col sm="3">
                  <LabelValue
                    label="Enable Reset Password"
                    value={getStatusString(
                      data?.permissions?.[Allowed_To_Reset_Password]
                    )}
                  />
                </Col>
                <Col sm="3">
                  <LabelValue
                    label="Email Configured for Password Reset"
                    value={
                      data?.permissions?.[
                        Allowed_To_Reset_Password_Admin_Email
                      ] || "-"
                    }
                  />
                </Col>
                <Col sm="3">
                  <LabelValue
                    label="Password Reset Access Expiry"
                    value={
                      data?.permissions?.[Allowed_To_Reset_Password_Expiry_Date]
                        ? `${formatInTimeZone(
                            data?.permissions?.[
                              Allowed_To_Reset_Password_Expiry_Date
                            ],
                            "UTC",
                            "MMM dd YYY HH:mm:ss"
                          )} UTC`
                        : "-"
                    }
                  />
                </Col>
              </Row>
            </div>
          );
        }}
      />
    </>
  );
};
export default Summary;
