import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Heading, Row, ToggleButton } from "orion";
import { FieldArray } from "formik";
import { endOfDay, addDays } from "date-fns";

import Field from "components/Field";
import { genericSelectTypes, productIDToName, PRODUCT_ID } from "consts";

const FormContainer = ({ formikProps, isEdit }) => {
  const getEodMoment = useCallback((nativeDate) => {
    return endOfDay(nativeDate);
  }, []);

  const [licenseExpiryMaxDate, setLicenseExpiryMaxDate] = useState(
    isEdit && formikProps.initialValues?.edition?.value === "evaluation"
      ? addDays(endOfDay(new Date()), 30)
      : null
  );

  const onLicenseTypeChangeHandler = useCallback(
    ({ value }) => {
      if (value === "evaluation") {
        const maxDate = addDays(endOfDay(new Date()), 30);
        if (!formikProps.values.expiryDate) {
          formikProps.setFieldValue("expiryDate", maxDate);
        }
        setLicenseExpiryMaxDate(maxDate);
      } else {
        setLicenseExpiryMaxDate(undefined);
      }
    },
    [formikProps]
  );
  useEffect(() => {
    formikProps.setFieldTouched("expiryDate", true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card
        className="mt-2"
        title="General"
        body={() => {
          return (
            <>
              <Field
                label="Organization Name"
                name="organizationName"
                dataTestId="sportal-add-msp-org-name"
                formikProps={formikProps}
                placeholder="Enter Organization Name"
              />
              <Field
                label="SFDC ID"
                name="sfdcId"
                dataTestId="sportal-add-msp-sfdc-id"
                formikProps={formikProps}
                placeholder="Enter SFDC ID"
                tooltip="SFDC ID should start with '001' and be 15 or 18 characters long"
              />
              <Field
                label="Work Email"
                name="email"
                dataTestId="sportal-add-msp-work-email"
                tooltip="Enter a work email address to add an MSP. Personal email addresses are not supported."
                tooltipProps={{ dataTestId: "sportal-add-msp-email-info" }}
                formikProps={formikProps}
                placeholder="Enter Email"
                disabled
              />
              <Field
                type={genericSelectTypes.editions}
                name="edition"
                label="License Type"
                placeholder="Select License Type"
                dataTestId="sportal-add-msp-edition-drpdwn"
                formikProps={formikProps}
                onChange={onLicenseTypeChangeHandler}
                downgradeAllowed={false}
              />
              <Field
                type="date"
                name="expiryDate"
                label="Expiry Date"
                placeholder="Select Date"
                dataTestId="sportal-add-msp-expiry-date"
                formikProps={formikProps}
                interceptor={getEodMoment}
                maxDate={licenseExpiryMaxDate}
              />
            </>
          );
        }}
      />
      <FieldArray
        name="products"
        render={(_) => (
          <>
            {formikProps.values.products.length > 0 &&
              formikProps.values.products.map((detail, index) => (
                <Card
                  className="mt-4"
                  key={`products-${detail.productID}`}
                  body={() => {
                    const isEnabled =
                      formikProps.values.products[index].enabled;

                    // Disabled Business if only product selected is Endpoints
                    const dataSources =
                      formikProps.values?.products[index]?.datasources;
                    const disabledEditions =
                      dataSources?.length > 0 &&
                      dataSources.every((sku) => sku.value === 3)
                        ? ["business"]
                        : [];

                    return (
                      <>
                        <Row className="oui-mb-4 oui-mt-1">
                          <Col>
                            <Heading
                              text={productIDToName[detail.productID]}
                              inline
                            />
                            <ToggleButton
                              isActive={isEnabled}
                              name={`products[${index}].enabled`}
                              onClick={(_, isActive) => {
                                formikProps.setFieldValue(
                                  `products[${index}].enabled`,
                                  isActive
                                );
                              }}
                              disabled={
                                isEdit &&
                                formikProps.initialValues.products?.[index]
                                  .enabled
                              }
                              validationProps={formikProps}
                              className="oui-d-inline oui-ml-3 oui-align-middle"
                              dataTestId={`sportal-add-msp-toggle-${index}`}
                            />
                            <div className="font-12 oui-text-muted oui-mt-1">
                              {`${productIDToName[detail.productID]} is ${
                                isEnabled ? "enabled" : "disabled"
                              }`}
                            </div>
                          </Col>
                        </Row>
                        {isEnabled && (
                          <>
                            <Field
                              type={genericSelectTypes.skus}
                              isMulti
                              name={`products[${index}].skus`}
                              label="Edition"
                              placeholder="Select"
                              dataTestId="sportal-add-msp-sku-drpdwn-hybrid-workload"
                              formikProps={formikProps}
                              disabledEditions={disabledEditions}
                              onChange={(value) => {
                                formikProps.setFieldValue(
                                  `products[${index}].skus`,
                                  value
                                );
                                if (
                                  value?.length > 0 &&
                                  !value?.some(
                                    (sku) =>
                                      sku.value === "elite" ||
                                      sku.value === "enterprise"
                                  )
                                ) {
                                  const datasource = (
                                    formikProps.values?.products?.[index]
                                      ?.datasources || []
                                  ).filter((ds) => ds.value !== 3);
                                  formikProps.setFieldValue(
                                    `products[${index}].datasources`,
                                    datasource
                                  );
                                }
                              }}
                            />
                            <Field
                              type={genericSelectTypes.storageRegions}
                              isMulti
                              name={`products[${index}].storageRegions`}
                              label="Storage Region"
                              placeholder="Select Region"
                              dataTestId="sportal-add-msp-storage-region-drpdwn-hybrid-workload"
                              formikProps={formikProps}
                              productId={detail.productID}
                            />
                            {detail.productID ===
                            PRODUCT_ID.saasAppsAndEndpoints ? (
                              <Field
                                type={genericSelectTypes.datasources}
                                isMulti
                                name={`products[${index}].datasources`}
                                label="Product"
                                placeholder="Select"
                                dataTestId={`sportal-edit-msp-product-drpdwn-${detail.productID}`}
                                formikProps={formikProps}
                                productId={detail.productID}
                                tooltip="Business Edition not supported for Endpoints"
                                isEndpointAllowedForEdition={(
                                  formikProps.values?.products[index]?.skus ||
                                  []
                                ).some(
                                  (sku) =>
                                    sku.value === "elite" ||
                                    sku.value === "enterprise"
                                )}
                                onChange={(value) => {
                                  formikProps.setFieldValue(
                                    `products[${index}].datasources`,
                                    value
                                  );
                                  if (
                                    value?.length === 1 &&
                                    value[0].value === 3
                                  ) {
                                    const skus = (
                                      formikProps.values?.products[index]
                                        .skus || []
                                    ).filter((sku) => sku.value !== "business");
                                    formikProps.setFieldValue(
                                      `products[${index}].skus`,
                                      skus
                                    );
                                  }
                                }}
                              />
                            ) : null}
                          </>
                        )}
                      </>
                    );
                  }}
                />
              ))}
          </>
        )}
      />
    </>
  );
};

export default FormContainer;
