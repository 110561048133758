import { addDays, endOfDay, isBefore } from "date-fns";

import { PRODUCT_ID } from "consts";

export const validationSchemaCreator = (Yup) => {
  return Yup.object().shape({
    organizationName: Yup.string().max(30, "Max 30 chars").required("Required"),
    sfdcId: Yup.string()
      .required("Required")
      .matches(/^[a-z0-9]+$/i, {
        excludeEmptyString: true,
        message: "Invalid SFDC ID. Only alphanumeric characters allowed.",
      })
      .test(
        "start",
        "Must start with '001'",
        (val) => (val || "").substr(0, 3) === "001"
      )
      .test(
        "length",
        "Must be exactly 15 or 18 characters",
        (val) => (val || "").length === 15 || (val || "").length === 18
      ),
    edition: Yup.mixed().required("Required"),
    timezone: Yup.mixed().required("Required"),
    expiryDate: Yup.date()
      .nullable()
      .required("Required")
      .test(
        "edition",
        "You can evaluate for a maximum of 30 days",
        function (value) {
          const { edition } = this.parent;
          if (edition?.value === "evaluation") {
            return isBefore(value, addDays(endOfDay(new Date()), 31));
          }
          return true;
        }
      ),
    user: Yup.object().shape({
      firstName: Yup.string().required("Required").max(30, "Max 30 chars"),
      lastName: Yup.string().required("Required").max(30, "Max 30 chars"),
      email: Yup.string()
        .email("Enter a valid email address.")
        .required("Required")
        .max(100, "Max 100 chars")
        .test(
          "restricted emails",
          "This email is part of restricted public domains: Please use your corporate email address.",
          (value) => {
            const whitlistedDomains = ["yahoo", "gmail", "hotmail", "rediff"];
            return !whitlistedDomains.some((domain) =>
              value?.includes(`@${domain}`)
            );
          }
        ),
      phone: Yup.string().required("Required").max(30, "Max 30 chars"),
    }),
    products: Yup.array()
      .of(
        Yup.object().shape({
          skus: Yup.array().test("enabled", "Required", function (value) {
            const { enabled } = this.parent;
            if (enabled) {
              return value.length > 0;
            }
            return true;
          }),
          storageRegions: Yup.array().test(
            "enabled",
            "Required",
            function (value) {
              const { enabled } = this.parent;
              if (enabled) {
                return value.length > 0;
              }
              return true;
            }
          ),
          datasources: Yup.array().test(
            "enabled",
            "Required",
            function (value) {
              const { enabled, productID } = this.parent;
              if (enabled && productID === PRODUCT_ID.saasAppsAndEndpoints) {
                return value.length > 0;
              }
              return true;
            }
          ),
        })
      )
      .test("products", function (value) {
        return value.some((v) => v.enabled);
      }),
  });
};

export const initalValues = {
  organizationName: "",
  sfdcId: "",
  edition: "",
  timezone: "",
  expiryDate: "",
  user: { firstName: "", lastName: "", email: "", phone: "" },
  products: [
    {
      enabled: false,
      productID: 1,
      skus: [],
      storageRegions: [],
      datasources: [],
    },
    {
      enabled: false,
      productID: 2,
      skus: [],
      storageRegions: [],
      datasources: [],
    },
  ],
};
